$(document).ready(function () {
  // 商品検索
  let sellSearchTimer;
  let sellSearchLastValue;
  $("#js-sell-search-form").on("keyup", function () {
    if (sellSearchLastValue != $(this).val()) {
      clearTimeout(sellSearchTimer);
      sellSearchLastValue = $(this).val();

      if (sellSearchLastValue.length > 0){
        sellSearchTimer = setTimeout(function () {
          $.ajax("/api/products/sell",
          {
            type: "get",
            data: { q: sellSearchLastValue, time: (new Date()).getTime() },
            dataType: "script",
          }
          ).done(function (data) {
          })
        }, 300)
      }
    }
  })

  // 画像アップロード
  $(".js-sell-photos-field").on("change", function(){
    Rails.fire($(this).closest("form")[0], "submit")
  })

  // 画像更新
  if ($("#js-sell-update-photo-form").length > 0) {
    $("body").on("click", ".js-sell-update-photo", function () {
      $("#js-sell-update-photo-form").find("[name='photo']").val($(this).data("id"))
      $("#js-sell-update-photo-form").find("[name='move']").val($(this).data("move"))
      Rails.fire($("#js-sell-update-photo-form")[0], "submit")
    })
  }

  // 画像削除
  if ($("#js-sell-delete-photo-form").length > 0) {
    $("body").on("click", ".js-sell-delete-photo", function () {
      if (window.confirm("削除しますか？")) {
        $("#js-sell-delete-photo-form").find("[name='photo']").val($(this).data("id"))
        Rails.fire($("#js-sell-delete-photo-form")[0], "submit")
      }
    })
  }

  // 画像アップロード・削除 ローディング
  if ($("#js-sell-loading").length > 0) {
    $(document).on("ajax:beforeSend", "form", function () {
      $("#js-sell-loading").show()
    })

    $(document).on("ajax:complete", "form", function () {
      $("#js-sell-loading").hide()
    })
  }

  // 状態入力
  const showRemarks = function() {
    let result = {}

    $(".js-sell-condition-field").each(function(){
      const condition = $(this).val()
      const target = $(this).data("target")

      if (!result[target]) {
        result[target] = "hide"
      }

      if ($(this).prop("checked") && condition === "bad") {
        result[target] = "show"
      }
    })

    $.each(result, function(key, val){
      const targetElement = $(`.js-sell-detail-field[data-target='${key}']`)
      if (val === "hide") {
        targetElement.fadeOut()
      } else {
        targetElement.fadeIn()
      }
    })
  }

  $(".js-sell-condition-field").on("change", function () {
    showRemarks()
  })

  // 手数料計算
  const calcProfit = function() {
    const price = $("#js-sell-price-field").val();
    if (price.length > 0) {
      if (price.match(/^[0-9]+$/)) {
        if (price < 1000) {
          $("#js-sell-price-errors").html("<p>販売額は1,000円以上に設定してください。</p>").fadeIn()
        } else if (price > 10000000) {
          $("#js-sell-price-errors").html("<p>販売額は9,999,999円以下に設定してください。</p>").fadeIn()
        } else {
          $("#js-sell-price-errors").empty().hide()
          let charge = Math.floor(price * 0.1)

          if (charge < 500) {
            charge = 500
          }

          $("#js-sell-charge").text(charge.toLocaleString())
          $("#js-sell-profit").text((price - charge).toLocaleString())
        }
      } else {
        $("#js-sell-price-errors").html("<p>販売額は半角数値で入力してください。</p>").fadeIn()
      }
    } else {
      $("#js-sell-price-errors").empty().hide()
    }
  }

  if ($("#js-sell-price-field").length > 0) {
    calcProfit()
  }

  $("#js-sell-price-field").on("keyup", function () {
    calcProfit()
  })

  // 公開
  $("#js-sell-publish").on("click", function () {
    $(this).after('<input type="hidden" name="publish" value="1" />')
  })

  // 下書きに戻す
  $("#js-sell-draft").on("click", function () {
    $(this).after('<input type="hidden" name="draft" value="1" />')
  })

  // 在庫
  let stockSearchTimer;
  let stockSearchLastValue = "";
  $("body").on("keyup", "#js-stock-search-q-field", function(){
    const currentValue = $(this).val()
    const id = $("#js-stock-search-id-field").val()
    const url = "/sell/stocks"

    if (stockSearchLastValue != currentValue) {
      clearTimeout(stockSearchTimer);

      if (currentValue.length > 0){
        stockSearchTimer = setTimeout(function () {
          $.ajax(url, {
            type: "get",
            data: { q: currentValue, id: id, time: (new Date()).getTime() },
            dataType: "script",
          })
        }, 300)
      } else {
        $("#js-stock-search-results").empty()
      }

      stockSearchLastValue = currentValue;
    }
  })
})
