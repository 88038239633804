$(document).ready(function() {
  $("#js-items-open-makers").on("click", function(){
    $(".js-maker").show()
    $(this).remove()
  })

  $(".js-items-check").on("change", function(){
    $(this).parents("form").submit()
  })
})
